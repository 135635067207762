<template>
  <div class="container">
    <div class="row">
      <a class="col-6 col-md-3 justify-content-center mb-4" target="_blank" href="https://www.vmons.ba/docs/statut.pdf">
        <img src="../assets/img/pdf.png" alt="PDF dokument" class="img-fluid mw-25">
        <p  class="text-center link-title align-self-center">Statut Vijeća mladih Općine Novo Sarajevo</p>
      </a>
      <a class="col-6 col-md-3 justify-content-center mb-4" target="_blank" href="https://www.vmons.ba/docs/vodic-full.pdf">
        <img src="../assets/img/pdf.png" alt="PDF dokument" class="img-fluid mw-25">
        <p  class="text-center link-title align-self-center">Vodič za mlade Općine Novo Sarajevo</p>
      </a>
      <a class="col-6 col-md-3 justify-content-center mb-4" target="_blank" href="https://www.vmons.ba/docs/zahtjev.pdf">
        <img src="../assets/img/pdf.png" alt="PDF dokument" class="img-fluid mw-25">
        <p  class="text-center link-title align-self-center">Zahtjev za upis u općinski spisak omladinskih udruženja</p>
      </a>
      <a class="col-6 col-md-3 justify-content-center mb-4" target="_blank" href="https://www.vmons.ba/docs/zakon.pdf">
        <img src="../assets/img/pdf.png" alt="PDF dokument" class="img-fluid mw-25">
        <p  class="text-center link-title align-self-center">Zakon o mladima</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
a:visited, a:hover, a:active, a:link, a{
  text-decoration: none;
  color:#555;
}

.link-title{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: black;
}

</style>