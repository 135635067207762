<template>
    <ViewDocument :document-link="documentLink"></ViewDocument>
</template>

<script setup>
import ViewDocument from "@/components/ViewDocument.vue";

const documentLink =  "https://docs.google.com/gview?url=https://vmons.ba/docs/vodic.pdf&embedded=true";

</script>

<style>

</style>

